import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const FooterNav = ({ className, ...props }) => {
  const classes = classNames('footer-nav', className);

  return (
    <nav {...props} className={classes}>
      <ul className='list-reset'>
        <li>
          <a
            href='https://app.cranetool.org/signup'
            target='_blank'
            rel='noopener noreferrer'
          >
            Tool
          </a>
        </li>
        <li>
          <Link to='/cranetool/'>About</Link>
        </li>
        <li>
          <Link to='/contact/'>Contact Us</Link>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
