import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class Partners extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'team section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'team-inner section-inner pt-0',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

    const sectionHeader = {
      title: 'Collaborations',
      paragraph:
        'We welcome the opportunity to work with members of our community. Here are some different ways to engage with us more formally.',
    };

    return (
      <section {...props} className={outerClasses}>
        <div className='container'>
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className='center-content reveal-from-bottom'
            />
            <div className={tilesClasses}>
              <div
                className='tiles-item reveal-from-bottom'
                data-reveal-container='.tiles-wrap'
              >
                <div className='tiles-item-inner'>
                  <div className='team-item-header'>
                    <div className='team-item-image mb-24'>
                      <Image
                        src={
                          require('../../assets/images/partners/mars.png')
                            .default
                        }
                        alt='Team member 01'
                        width={100}
                        height={100}
                      />
                    </div>
                  </div>
                  <div className='team-item-content'>
                    <h5 className='team-item-name mt-0 mb-4'>MaRS</h5>
                    <p className='m-0 text-sm'>
                      The CRANE tool was used to evaluate the ERP of final
                      ventures in the Mission from MaRS challenge.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className='tiles-item reveal-from-bottom'
                data-reveal-container='.tiles-wrap'
              >
                <div className='tiles-item-inner'>
                  <div className='team-item-header'>
                    <div className='team-item-image mb-24'>
                      <Image
                        src={
                          require('../../assets/images/partners/cleantech.jpeg')
                            .default
                        }
                        alt='Team member 01'
                        width={180}
                        height={180}
                      />
                    </div>
                  </div>
                  <div className='team-item-content'>
                    <h5 className='team-item-name mt-0 mb-4'>
                      Clean Tech Open
                    </h5>
                    <p className='m-0 text-sm'>
                      Ventures learned how to use the CRANE tool to evaluate
                      their technologies in one-on-one sessions.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={tilesClasses}>
              <div
                className='tiles-item reveal-from-bottom'
                data-reveal-container='.tiles-wrap'
              >
                <div className='tiles-item-inner'>
                  <div className='team-item-header'>
                    <div className='team-item-image mb-24'>
                      <Image
                        src={
                          require('../../assets/images/partners/chainreaction.png')
                            .default
                        }
                        alt='Team member 01'
                        width={260}
                        height={260}
                      />
                    </div>
                  </div>
                  <div className='team-item-content'>
                    <h5 className='team-item-name mt-0 mb-4'>
                      Chain Reaction Innovations
                    </h5>
                    <p className='m-0 text-sm'>
                      The CRANE tool was used to evaluate the ERP of alumni
                      companies.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className='tiles-item reveal-from-bottom'
                data-reveal-container='.tiles-wrap'
              >
                <div className='tiles-item-inner'>
                  <div className='team-item-header'>
                    <div className='team-item-image mb-24'>
                      <Image
                        src={
                          require('../../assets/images/partners/bev.jpeg')
                            .default
                        }
                        alt='Team member 01'
                        width={240}
                        height={240}
                      />
                    </div>
                  </div>
                  <div className='team-item-content'>
                    <h5 className='team-item-name mt-0 mb-4'>
                      Breakthrough Energy
                    </h5>
                    <p className='m-0 text-sm'>
                      Used CRANE’s ERP approach as an input to evaluate
                      emissions avoided that are a consequence of early
                      investment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Partners.propTypes = propTypes;
Partners.defaultProps = defaultProps;

export default Partners;
