import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';

import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Button from '../elements/Button';

const SLACK_INVITE_ENDPOINT = 'https://myteam.slack.com/api/users.admin.invite';
const capitalizeFirstLetter = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
  hasrecaptcha: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
  hasrecaptcha: true,
};

class Cta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      status: '',
      hint: '',
      loading: false,
    };
    this.recaptchaRef = React.createRef();
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onCaptchaLoaded = this.onCaptchaLoaded.bind(this);
    this.requestSlackInvite = this.requestSlackInvite.bind(this);
  }

  onCaptchaLoaded() {
    this.setState({ captchaLoaded: true });
  }

  onSubmitHandler(e) {
    e.preventDefault();
    if (!!this.recaptchaRef.current.getValue()) {
      this.requestSlackInvite(e);
    } else {
      this.recaptchaRef.current.execute();
      this.requestSlackInvite(e);
    }
  }

  validateEmail = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };

  handleInputChange = (e) => {
    const { value } = e.target;
    this.setState({
      email: value,
    });
  };

  requestSlackInvite = (e) => {
    this.setState({ loading: true });
    const { email } = this.state;
    var QUERY_PARAMS = `email=${email}&token=${process.env.REACT_APP_SLACK_TOKEN}&set_active=true`;

    if (!this.validateEmail(email)) {
      this.setState({
        email: '',
        status: 'error',
        loading: false,
        hint: 'Your email seems to be incorrect',
      });
      return;
    }

    axios.post(`${SLACK_INVITE_ENDPOINT}?${QUERY_PARAMS}`).then((res) => {
      let errorText = '';
      if (res.data.ok) {
        this.setState({
          email: '',
          status: 'success',
          loading: false,
        });
      } else {
        res.data.error === 'already_in_team_invited_user'
          ? (errorText =
              'Your invite is already sent! Make sure to check your Spam Folder')
          : (errorText = capitalizeFirstLetter(res.data.error).replace(
              /_/g,
              ' '
            ));
        this.setState({
          status: 'error',
          loading: false,
          hint: errorText,
        });
      }
    });
  };

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      hasrecaptcha,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'cta section center-content-mobile',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'cta-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
      split && 'cta-split'
    );

    return (
      <section {...props} className={outerClasses}>
        <div className='container'>
          <div className={innerClasses}>
            <div className='cta-slogan'>
              <h3 className='m-0'>Join our Slack Community</h3>
              <p className='m-0' style={{ marginTop: '10px', color: '#FFF' }}>
                Unlock the full potential of CRANE by joining its growing
                community, where you can get expert advice, access important
                resources, and connect with like-minded individuals.
              </p>
            </div>
            <div className='cta-action'>
              <form onSubmit={(e) => this.onSubmitHandler(e)} status='error'>
                <Input
                  id='newsletter'
                  type='email'
                  label='Subscribe'
                  labelHidden
                  hasIcon='right'
                  placeholder='Your best email address'
                  size='sm'
                  formGroup='desktop'
                  status={this.state.status}
                  hint={this.state.hint}
                  onChange={this.handleInputChange}
                  value={this.state.email}
                >
                  <Button
                    color='secondary'
                    size='sm'
                    loading={this.state.loading}
                    type='submit'
                    disabled={!this.state.captchaLoaded}
                  >
                    Join Now
                  </Button>
                </Input>
                {hasrecaptcha && (
                  <ReCAPTCHA
                    ref={this.recaptchaRef}
                    size='invisible'
                    sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                    onChange={(e) => (response) => {
                      if (response) {
                        this.requestSlackInvite(e);
                      } else {
                        this.recaptchaRef.current.execute();
                      }
                    }}
                    asyncScriptOnLoad={this.onCaptchaLoaded}
                  />
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
