import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';

import HeroVideo from './../../assets/videos/Mini-CRANE-Demo.mp4';

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

class HeroFull extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    return (
      <section {...props} className={outerClasses}>
        <div className='container-sm'>
          <div className={innerClasses}>
            <div className='hero-content'>
              <h1
                className='mt-0 mb-16 reveal-from-top'
                data-reveal-delay='150'
              >
                Discover the Future Impact of Climate Technologies
              </h1>
              <div className='container-xs'>
                <p
                  className='m-0 mb-32 reveal-from-top'
                  data-reveal-delay='300'
                >
                  As the only free, comprehensive, and open-access resource of
                  its kind, the CRANE tool empowers investors and entrepreneurs
                  of all types to make objective decisions using science-based
                  climate impact data.
                </p>
                <div className='reveal-from-top' data-reveal-delay='450'>
                  <Button
                    tag='a'
                    color='primary'
                    href='https://app.cranetool.org/signup'
                  >
                    Access CRANE
                  </Button>
                  <Button
                    tag='a'
                    color='secondary'
                    href='https://secure.qgiv.com/for/pci'
                    style={{ marginLeft: '10px' }}
                  >
                    Support Us
                  </Button>
                </div>
              </div>
            </div>
            <div
              className='hero-figure reveal-from-bottom'
              data-reveal-delay='600'
            >
              <video
                className='has-shadow'
                width={896}
                height={504}
                controls // Remove if you don't want video controls
                autoPlay // Automatically play video when loaded
                loop // Loop video playback
                muted // Mute video by default
                playsInline // For mobile browsers to play video inline
                onClick={() => window.open('https://app.cranetool.org/signup', '_blank', 'noopener,noreferrer')}
              >
                <source src={HeroVideo} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

HeroFull.propTypes = propTypes;
HeroFull.defaultProps = defaultProps;

export default HeroFull;
