import React from 'react';
import classNames from 'classnames';
// import sections
import Cta from '../components/sections/Cta';
import GenericSection from '../components/sections/GenericSection';
import Roadmap from '../components/sections/Roadmap';
import Teams from '../components/sections/Teams';
import DonateCTA from '../components/sections/DonateCTA';

class About extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      hasBgColor,
      invertColor,
    } = this.props;

    const outerClasses = classNames(
      'team section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    return (
      <section className={outerClasses}>
        <Teams />
        <DonateCTA />
        <GenericSection>
          <div className='container'>
            <h2 className='mt-0 center-content'>CRANE Backstory</h2>
            <p>
              Many government agencies, investors, and philanthropists share a
              common interest in supporting early-stage companies that promise
              dramatic reductions in global greenhouse gas emissions. In some
              cases, investors aspire to use the potential climate impact of a
              new venture as part of their decision-making process about whether
              or not to invest. For many, climate impact assessment is also
              important after making an investment for reporting purposes. For
              some investors, they are merely seeking awareness.
            </p>
            <p>
              Despite the importance of guiding investments to early-stage
              ventures with large potential climate impact, early-stage
              investors lacked a common lexicon to discuss the potential climate
              impacts of early-stage companies. Prior to CRANE, there has not
              been a standard methodology for assessing the potential climate
              impact of a new ventures and the technologies, solutions, or
              business models they bring to market.
            </p>
            <p>
              Existing climate impact assessment tools and services were
              designed exclusively to retrospectively assess the climate impact
              of a business as it exists today. For early- stage businesses with
              small operations and limited to no product deployment, these tools
              and services did not provide actionable insights.{' '}
              <strong>
                There was a gap in the marketplace for tools to inform investors
                about the potential for their investments to mitigate future
                emissions.
              </strong>
            </p>
            <p>
              In an attempt to build a better, universal climate impact
              assessment tool, Prime Coalition partnered with NYSERDA in 2017 to
              codify methodology for assessing the potential climate impact of
              early-stage ventures.
            </p>
            <p>
              In 2019, NYSERDA, the John D. and Catherine T. MacArthur
              Foundation, and Massachusetts Clean Energy Center supported Prime
              and three additional development partners - Greenometry, Rho
              Impact (formerly Rho AI) and Clean Energy Trust - to build a tool
              that makes the methodology available to anyone that might want to
              consider climate impact in their decision making: CRANE.
            </p>
            <p>
              It is our goal to make the CRANE tool available to as many users as
              possible for free, whether your interest is in using impact
              potential as part of your pre-investment due diligence,
              post-investment tracking and reporting, or general awareness for
              your team.
            </p>
          </div>
        </GenericSection>
        <Roadmap />
        <GenericSection topDivider>
          <div className='container'>
            <h2 className='mt-0'>
              We’re approaching CRANE’s ambitions with humility
            </h2>
            <p>
              Estimating impact potential is difficult, and doing it across
              hundreds of technologies is ambitious. We hope our users will view
              CRANE output reports as a helpful starting place for considering
              climate impact in their own diligence, reporting and
              conversations. Note that the CRANE tool does not precisely forecast the
              future, but rather provides an estimate for future impact
              potential.
            </p>
          </div>
        </GenericSection>
        <Cta hasBgColor invertColor split className='has-bg-color-cut' />
      </section>
    );
  }
}

export default About;
