import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class Teams extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "team center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "team-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: "Meet the teams behind CRANE",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content reveal-from-top"
            />
            <div className={tilesClasses}>
              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div
                    className="team-item-header reveal-from-top"
                    data-reveal-container=".tiles-item"
                  >
                    <div className="team-item-image mb-24 illustration-element-06">
                      <a
                        href="https://primecoalition.org/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image
                          src={
                            require("./../../assets/images/prime-coalition-logo-380x380.png")
                              .default
                          }
                          alt="Prime Coalition Logo"
                          width={190}
                        />
                      </a>
                    </div>
                  </div>
                  <div
                    className="team-item-content reveal-from-top"
                    data-reveal-container=".tiles-item"
                    data-reveal-delay="200"
                  >
                    <h5 className="team-item-name mt-0 mb-4">
                      Prime Coalition
                    </h5>
                    <div className="team-item-role text-xxs tt-u text-color-primary mb-8">
                      Management &amp; Funding
                    </div>
                    <p className="m-0 text-sm">
                      Prime Coalition plays a key role in codifying methodology
                      for assessing the potential climate impact of early-stage
                      ventures. This is part of Prime's effort to improve
                      forward-looking climate impact assessment across the
                      early-stage investment field.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div
                    className="team-item-header reveal-from-top"
                    data-reveal-container=".tiles-item"
                    data-reveal-delay="150"
                  >
                    <div className="team-item-image mb-24">
                      <a
                        href="https://www.rhoimpact.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image
                          src={
                            require("./../../assets/images/rho-impact-logo-380x380.png")
                              .default
                          }
                          alt="Rho Impact Logo"
                          width={190}
                        />
                      </a>
                    </div>
                  </div>
                  <div
                    className="team-item-content reveal-from-top"
                    data-reveal-container=".tiles-item"
                    data-reveal-delay="350"
                  >
                    <h5 className="team-item-name mt-0 mb-4">Rho Impact</h5>
                    <div className="team-item-role text-xxs tt-u text-color-primary mb-8">
                      Development &amp; Methodology
                    </div>
                    <p className="m-0 text-sm">
                      Rho Impact leads the development and contributes to the
                      core methodology behind the CRANE tool. Rho works directly
                      with investors and climate tech startups to help advance
                      the state-of-the-art in credible impact forecasting.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Teams.propTypes = propTypes;
Teams.defaultProps = defaultProps;

export default Teams;
