import React from 'react';
import ContactForm from '../components/sections/ContactForm';
import Cta from '../components/sections/Cta';

class Contact extends React.Component {
  render() {
    return (
      <>
      <ContactForm className="illustration-section-01" />
      <Cta hasBgColor invertColor split className="has-bg-color-cut" />
      </>
    );
  }
}

export default Contact;