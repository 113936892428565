import React from 'react';
import HeroFull from '../components/sections/HeroFull';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Testimonial from '../components/sections/Testimonial';
import DonateCTA from '../components/sections/DonateCTA';
import Cta from '../components/sections/Cta';

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <HeroFull className="illustration-section-01" />
        <FeaturesTiles topDivider />
        <DonateCTA />
        <Testimonial topDivider />
        <Cta hasBgColor invertColor split className="has-bg-color-cut" />
      </React.Fragment>
    );
  }
}

export default Home;