import React from "react";
// import section header

// import sections
// import FeaturesTabs from "../components/sections/FeaturesTabs";
import NewsTiles from "../components/sections/NewsTiles";
import Cta from "../components/sections/Cta";

class News extends React.Component {
  render() {
    return (
      <React.Fragment>
        <NewsTiles />
        {/* <FeaturesTabs /> */}
        <Cta invertColor split className="has-bg-color-cut" />
      </React.Fragment>
    );
  }
}

export default News;
