import React from "react";
import { withRouter, Switch } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";
import ReactGA from 'react-ga';

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";
import LayoutAlternative from "./layouts/LayoutAlternative";

// Views
import Home from "./views/Home";
import About from "./views/About";
import Tool from "./views/Tool";
import News from "./views/News";
import Partnerships from "./views/Partnerships";
import Contact from "./views/Contact";

class App extends React.Component {
  componentDidMount() {
    document.body.classList.add("is-loaded");
    this.refs.scrollReveal.init();
    ReactGA.initialize('UA-154290640-2')
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute
              exact
              path="/cranetool"
              component={About}
              layout={LayoutAlternative}
            />
            <AppRoute
              exact
              path="/contact"
              component={Contact}
              layout={LayoutAlternative}
            />
            <AppRoute
              exact
              path="/collaborations"
              component={Partnerships}
              layout={LayoutAlternative}
            />
            <AppRoute
              exact
              path="/news"
              component={News}
              layout={LayoutAlternative}
            />
            <AppRoute
              exact
              path="/resources"
              component={Tool}
              layout={LayoutAlternative}
            />
          </Switch>
        )}
      />
    );
  }
}

export default withRouter((props) => <App {...props} />);
