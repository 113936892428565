import React from "react";
import classNames from "classnames";
// import sections

import Team from "../components/sections/Team";
import DonateCTA from '../components/sections/DonateCTA';

class Partnerships extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      hasBgColor,
      invertColor,
    } = this.props;

    const outerClasses = classNames(
      "team section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    return (
      <section className={outerClasses}>
        <Team/>
        <DonateCTA />
        <div className="pb-32"/>
      </section>
    );
  }
}

export default Partnerships;
