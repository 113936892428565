import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class FeaturesTiles extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tiles section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tiles-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

    const sectionHeader = {
      title: 'Key Features',
    };

    return (
      <section {...props} className={outerClasses}>
        <div className='container'>
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className='center-content' />
            <div className={tilesClasses}>
              <div
                className='tiles-item reveal-from-bottom'
                data-reveal-container='.tiles-wrap'
              >
                <div className='tiles-item-inner'>
                  <div className='features-tiles-item-header'>
                    <div className='features-tiles-item-image mb-16'>
                      <Image
                        src={
                          require('./../../assets/images/feature-tile-icon-04.svg')
                            .default
                        }
                        alt='Features tile icon 01'
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className='features-tiles-item-content'>
                    <h4 className='mt-0 mb-8'>
                      Forward-looking GHG Emissions Modeling
                    </h4>
                    <p className='m-0 text-sm'>
                      CRANE offers a comprehensive taxonomy and robust data
                      modeling capabilities. This allows you to quickly
                      aggregate and analyze forward-looking GHG emissions across
                      200+ technology models, with the flexibility to adjust
                      data inputs and create custom reports.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className='tiles-item reveal-from-bottom'
                data-reveal-container='.tiles-wrap'
                data-reveal-delay='100'
              >
                <div className='tiles-item-inner'>
                  <div className='features-tiles-item-header'>
                    <div className='features-tiles-item-image mb-16'>
                      <Image
                        src={
                          require('./../../assets/images/feature-tile-icon-01.svg')
                            .default
                        }
                        alt='Features tile icon 02'
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className='features-tiles-item-content'>
                    <h4 className='mt-0 mb-8'>
                      Standardized Impact Methodology
                    </h4>
                    <p className='m-0 text-sm'>
                      Aligned with Project Frame’s transparent and standardized{' '}
                      <a
                        href='https://projectframe.how/preinvestment-ghg-assessment'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        methodology
                      </a>
                      , CRANE ensures consistent and reliable climate impact
                      assessments. Designed for users at all expertise levels,
                      the tool calculates both planned (company-level) and
                      potential (technology-level) GHG impacts.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className='tiles-item reveal-from-bottom'
                data-reveal-container='.tiles-wrap'
                data-reveal-delay='200'
              >
                <div className='tiles-item-inner'>
                  <div className='features-tiles-item-header'>
                    <div className='features-tiles-item-image mb-16'>
                      <Image
                        src={
                          require('./../../assets/images/feature-tile-icon-06.svg')
                            .default
                        }
                        alt='Features tile icon 03'
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className='features-tiles-item-content'>
                    <h4 className='mt-0 mb-8'>Automated Report Generation</h4>
                    <p className='m-0 text-sm'>
                      CRANE automatically generates detailed reports that
                      summarize GHG impact calculations, references, and
                      assumptions. These easy-to-share assessments can support
                      investment decisions, impact reporting, fundraising, and
                      target-setting.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
