import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Timeline from '../elements/Timeline';
import TimelineItem from '../elements/TimelineItem';

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

class Roadmap extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'roadmap section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'roadmap-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Tool Timeline',
    };

    return (
      <section {...props} className={outerClasses}>
        <div className='container'>
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className='center-content' />
            <Timeline>
              <TimelineItem title='January 2019'>
                Team CRANE met in Boston to kickstart the back-end design
                process and scope critical specifications for users. CRANE’s
                Academic Advisory Group and Super User Group were both convened
                for the first time.
              </TimelineItem>
              <TimelineItem title='July 2019'>
                CRANE convened two large events at the ARPA-E Summit in Denver,
                CO, to better inform what was “in scope” and “out of scope” for
                v1 of the tool. One event focused on the credibility and rigor
                of back-end methodology and one event focused on the needs of
                potential users.
              </TimelineItem>
              <TimelineItem title='November 2019'>
                CRANE deployed as a beta version and began testing for feedback
                with 300+ beta users. Improvements were made in real time as
                feedback flowed in over four months of live testing.
              </TimelineItem>
              <TimelineItem title='April 2020'>
                CRANE publicly launched v1 with plans for future versions that
                support the public good along with enterprise features for users
                that need custom solutions.
              </TimelineItem>
              <TimelineItem title='November 2020'>
                CRANE &mdash;facilitated by Rho Impact, is used to evaluate the
                potential impact of over 35 MaRS Discovery District competition
                applicants.
              </TimelineItem>
              <TimelineItem title='May 2021'>
                CRANE Custom tech module is released. Rho Impact builds the new
                CRANE module based on user feedback, to enable users to generate
                customized technology solutions for CRANE evaluations.
              </TimelineItem>
              <TimelineItem title='July 2021'>
                CRANE User Report published in collaboration with Prime and Rho
                Impact. Feedback from CRANE users to publish a report
                identifying pain points in the impact due diligence process.
              </TimelineItem>
              <TimelineItem title='April 2024'>
                Global launch of general availability of the CRANE tool based on
                years of real world use and feedback.
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </section>
    );
  }
}

Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default Roadmap;
