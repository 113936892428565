import React from "react";
import classNames from "classnames";

import { SectionProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Input from "../elements/Input";
import Button from "../elements/Button";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: "", email: "", message: "" };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state }),
    })
      .then(
        this.setState({
          name: "",
          email: "",
          message: "",
        })
      )
      .catch((error) => alert(error));
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const { name, email, message } = this.state;

    const outerClasses = classNames(
      "signin section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "signin-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const sectionHeader = {
      title: "Contact the CRANE team",
      paragraph:
        "Please contact us with your questions, concerns, ideas, and suggestions. The Rho Impact team will respond to you as possible.",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container" style={{ minHeight: "65vh" }}>
          <div className={innerClasses}>
            <SectionHeader
              tag="h1"
              data={sectionHeader}
              className="center-content"
            />
            <div className="tiles-wrap">
              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <form
                    onSubmit={this.handleSubmit}
                    name="contact"
                    method="POST"
                    data-netlify="true"
                    action="/contact/?success=true"
                    netlify-honeypot="bot-field"
                  >
                    <fieldset>
                      <div className="mb-12">
                        <label className="form-label" htmlFor="full_name">
                          Full name
                        </label>
                        <Input
                          id="name"
                          type="text"
                          name="name"
                          value={name}
                          onChange={this.handleChange}
                          label="Your Name"
                          placeholder="Full name"
                          labelHidden
                          required
                        />
                      </div>
                      <div className="mb-12">
                        <label className="form-label" htmlFor="email">
                          Email
                        </label>
                        <Input
                          id="email"
                          className="form-input w-full text-gray-800"
                          type="email"
                          name="email"
                          value={email}
                          onChange={this.handleChange}
                          placeholder="Your Email Address"
                          label="Your Email"
                          labelHidden
                          required
                        />
                      </div>
                      <div className="mb-12">
                        <label className="form-label" htmlFor="message">
                          Your Message
                        </label>
                        <textarea
                          id="message"
                          type="textarea"
                          name="message"
                          value={message}
                          onChange={this.handleChange}
                          placeholder="Tell us more about how we can help"
                          className="form-input"
                          required
                          rows="4"
                        ></textarea>
                      </div>
                      <div className="mt-24 mb-32">
                        <Button color="primary" wide type="submit">
                          Send
                        </Button>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ContactForm.propTypes = propTypes;
ContactForm.defaultProps = defaultProps;

export default ContactForm;
