import React, { useState } from 'react';
import Image from '../components/elements/Image';
import '../assets/scss/docs.scss';
import HeroFullVideo from '../components/sections/HeroFullVideo';
// import PageIllustration from '../partials/PageIllustration';

function Help() {
  const [page, setPage] = useState(1);

  return (
    <div className='flex flex-col min-h-screen overflow-hidden mt-32'>
      {/*  Page content */}
      <main className='flex-grow mt-32 mb-64'>
        {/*  Page illustration */}
        {/* <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div> */}

        {/*  Page sections */}
        <div className='relative max-w-6xl mx-auto px-4 sm:px-6 mt-32'>
          <div className='pt-32 pb-12 md:pt-40 md:pb-20'>
            <div className='flex flex-col md:flex-row'>
              {/* Main content */}
              <main
                className='md:flex-auto md:pl-10 order-1'
                data-aos='fade-up'
              >
                {/* Getting Started with CRANE */}
                <div className={page !== 1 ? 'hidden' : undefined}>
                  <div className='mb-8'>
                    <h2 className='h2 mb-4'>
                      Getting Started with the CRANE Tool
                    </h2>
                    {/* <p className="text-gray-400">
                      Last updated -{" "}
                      <span className="text-green-500">September 23, 2021</span>
                    </p> */}
                  </div>
                  <h3>What is CRANE?</h3>
                  <div className='mb-32 ml-32'>
                    <Image
                      src={
                        require('../assets/images/get_started_01.png').default
                      }
                      alt='process diagram'
                      width={780}
                      height={300}
                    />
                  </div>
                  <p className='text-gray-500'>
                    <strong>
                      CRANE (Carbon Reduction Assessment for New Enterprises) is
                      an open access, web-based application that allows users to
                      evaluate the greenhouse gas (GHG) reduction potential of
                      emerging technologies
                    </strong>{' '}
                    The goal of the software is to greatly reduce the time and
                    resources required for investors, entrepreneurs, government
                    agencies, incubators, and philanthropies to perform
                    forward-facing, rigorous and transparent climate impact
                    assessments. The key result is an{' '}
                    <strong>impact potential</strong> for the technology or
                    company, which is the magnitude of the greenhouse gas
                    emissions in million metric tons of carbon dioxide
                    equivalent (MMtCO2e) that have the potential to be avoided
                    or abated as a result of deploying the new technology. Every
                    analysis includes a summary report that provides additional
                    metrics, assumptions, references, and calculations. Each
                    analysis can be downloaded in multiple formats and shared
                    among stakeholders for further review and improvement.
                    <br />
                    <br />
                    CRANE’s mission is to make GHG modeling capabilities
                    publicly and globally available, while contributing to a
                    digital ecosystem of organizations and people working on
                    real climate solutions. The CRANE tool does not precisely
                    forecast the future, but rather provides an estimate for
                    future impact potential and a logical basis for that
                    estimate. We hope our users will view CRANE output reports
                    as a helpful starting place for considering climate impact
                    in their own diligence, integrated reporting and
                    conversations.
                  </p>
                  <a
                    href='https://drive.google.com/file/d/18Ne7jyUgxcPVWrbt6jvaFirznyvfsYlm/view?usp=drive_link'
                    target='_blank'
                    rel='noreferrer'
                  >
                    [Click here to download a shareable 1-page overview of
                    CRANE]
                  </a>
                  <h3>CRANE's Methodology</h3>
                  <p className='text-gray-500'>
                    The CRANE methodology originated from Prime’s in-house
                    process, which was formalized and published in a 2018 report
                    entitled Climate Impact Assessment for Early Stage Ventures.
                    Since then, the methodology has been subtly refined in order
                    for it to be applicable to a broad range of sectors and
                    manifested in software. However, the core framework and
                    approach has not been altered.
                  </p>
                  <div className='flex flex-row mb-32 ml-32'>
                    <Image
                      src={
                        require('../assets/images/methodology_01.png').default
                      }
                      alt='process diagram'
                      width={380}
                      height={300}
                    />
                    <Image
                      src={
                        require('../assets/images/methodology_02.png').default
                      }
                      alt='process diagram'
                      width={380}
                      height={300}
                    />
                  </div>
                  <p className='text-gray-500'>
                    The high-level approach of the methodology is to calculate
                    the difference in emissions between two divergent futures
                    (scenarios):
                  </p>
                  <ul className='text-gray-600'>
                    <li>
                      The Reference Scenario, which may be any of a range of
                      possible futures in which the new technology has not been
                      deployed, and the market demands continue to grow at
                      expected rates
                    </li>
                    <li>
                      The Solution Scenario, a future in which the new
                      technology has been deployed.
                    </li>
                  </ul>
                  <p className='text-gray-500'>
                    Each scenario includes projections of the Total Available
                    Market (the largest or broadest market that a technology
                    could theoretically displace), Target Market (the specific
                    market that a technology will displace over time), and
                    Established Market if applicable (an existing, clean
                    technology market that falls within the same total available
                    market as the new technology). The Solution Scenario also
                    includes the Solution Market (the market share captured by
                    the New Technology). The remaining market is the Total
                    Available Market less all the other markets incorporated in
                    the analysis.
                  </p>
                  <p className='text-gray-500'>
                    For more detailed information on the calculations, we
                    recommend reviewing the Calculation References tab of a
                    CRANE analysis.
                  </p>
                  <p className='text-gray-500'>
                    This framework does not assess the probability of a given
                    venture to achieve commercial success. Rather, it focuses on
                    describing ERPs that allow investors in a variety of
                    industries and subsectors to compare the potential impact of
                    one venture relative to another and to investigate the
                    underlying assumptions.
                  </p>
                </div>

                {/* Required Inputs & Key Outputs */}
                <div className={page !== 2 ? 'hidden' : undefined}>
                  <div className='mb-8'>
                    <h2 className='h2 mb-4'>
                      Customizable CRANE Inputs and Key Outputs
                    </h2>
                    {/* <p className="text-gray-400">
                      Last updated -{" "}
                      <span className="text-green-500">March 25, 2022</span>
                    </p> */}
                  </div>
                  <h3>What information to bring with you to CRANE</h3>
                  <p className='text-gray-500'>
                    CRANE provides a portion of the data that is required to
                    conduct an impact analysis, but it is also expected that a
                    company will provide key information that is specific to
                    their technology or venture. The information below describes
                    what data CRANE brings to the table and what a venture
                    should be expected to provide.
                  </p>
                  <h3>Required Inputs</h3>
                  <h5>From the User</h5>
                  <ul className='text-gray-600'>
                    <li>
                      Geographic scope of analysis - This is the geographic
                      region that you expect to adopt your technology. Most of
                      the CRANE technology models are global, but we are
                      currently expanding the available CRANE geographies. If
                      the default CRANE geography is not suitable for your
                      application, you can modify it by adding your own data in
                      the Custom Technology Module.
                    </li>
                    <li>
                      Time horizon of analysis - This is the start and end point
                      for the analysis. CRANE restricts the analysis projections
                      to a maximum of 2060 for the final year of the analysis.
                    </li>
                    <li>
                      Technology area selection - This includes identifying the
                      most appropriate technology model in CRANE. Sometimes
                      technologies that do not at first seem like a direct fit
                      can be modified appropriately for a reasonable analysis.
                      It can be a challenge to identify the best model, so there
                      is more guidance on this below.
                    </li>
                    <li>
                      Projected market penetration - CRANE provides default
                      values for projected market penetration, but these should
                      almost always be updated by the venture for a more
                      accurate analysis. CRANE currently does not support
                      projected unit sales as a direct input, so some
                      conversation may be necessary to reach the appropriate
                      input units. The units are always a percentage of the
                      target market.
                    </li>
                    <li>
                      Key physical performance metrics or targets - This is a
                      commonly used performance metric for the specific
                      technology, such as fuel efficiency improvement or percent
                      energy savings. CRANE provides default values for an
                      expected similar technology, but this will likely need to
                      be updated if the analysis is for a specific company’s
                      technology.
                    </li>
                    <li>
                      Current hypothesis around impact potential (i.e. mechanism
                      of mitigating or avoiding emissions)
                    </li>
                  </ul>

                  <h5>From CRANE</h5>
                  <ul className='text-gray-600'>
                    <li>Total Market and target market projection(s)</li>
                    <li>Corresponding industry emissions projection(s)</li>
                    <li>
                      Key performance metrics and/or GHG intensity of incumbent
                      technologies (i.e. those which are expected to be
                      displaced by solution)
                    </li>
                  </ul>

                  <h5>Key Outputs</h5>
                  <ul className='text-gray-600'>
                    <li>
                      Estimate of range of cumulative GHG impact potential to
                      end year
                    </li>
                    <li>
                      Estimate of the annual GHG impact potential per company to
                      end year
                    </li>
                    <li>CRANE analysis file (can be shared and updated)</li>
                    <li>
                      CRANE output report w/ calculation and documentation of
                      references
                    </li>
                  </ul>
                </div>

                {/* Registration */}
                <div className={page !== 3 ? 'hidden' : undefined}>
                  <div className='mb-8'>
                    <h2 className='h2 mb-4'>Registering an Account</h2>
                    {/* <p className="text-gray-400">
                      Last updated -{" "}
                      <span className="text-green-500">June 30, 2020</span>
                    </p> */}
                  </div>
                  <h3>
                    How do I sign up for the CRANE tool and how much does it
                    cost?
                  </h3>
                  <p className='text-gray-500'>
                    CRANE is open access and free to use. You can sign up for
                    the tool{' '}
                    <a
                      href='https://app.cranetool.org/signup'
                      target='_blank'
                      rel='noreferrer'
                    >
                      here
                    </a>{' '}
                    and access the tool{' '}
                    <a
                      href='https://app.cranetool.org/login'
                      target='_blank'
                      rel='noreferrer'
                    >
                      here.
                    </a>{' '}
                    In the future, the CRANE Team is developing additional tools
                    to supplement the main application, some of which may
                    require paid subscriptions, but the currently available
                    CRANE software, as well as development that is necessary for
                    the public good, will always remain free to use.
                  </p>
                </div>

                {/* Custom Tech */}
                <div className={page !== 4 ? 'hidden' : undefined}>
                  <div className='mb-8'>
                    <h2 className='h2 mb-4'>Custom Technology Module</h2>
                    {/* <p className="text-gray-400">
                      Last updated -{" "}
                      <span className="text-green-500">June 30, 2020</span>
                    </p> */}
                  </div>
                  <h3>I can’t find my technology in CRANE, what do I do?</h3>
                  <p className='text-gray-500'>
                    One of the biggest challenges in generating a CRANE analysis
                    is finding the right technology model in CRANE. This diagram
                    can help guide you to finding the best technology model. All
                    existing CRANE technology models as well as their details
                    can be found{' '}
                    <a
                      href='https://app.cranetool.org/technology_archive'
                      target='_blank'
                      rel='noreferrer'
                    >
                      here.
                    </a>{' '}
                    Our backlog of technologies can be found{' '}
                    <a
                      href='https://docs.google.com/spreadsheets/d/1112TGsp0bpCAdzU9QjvAp7YJjoioFexydXZn4zaFf54/edit#gid=0'
                      target='_blank'
                      rel='noreferrer'
                    >
                      here.
                    </a>
                  </p>
                  <p className='text-gray-500'>
                    Although we have over 200 verified technology models in
                    CRANE, sometimes there is still no suitable existing CRANE
                    model to represent your technology. For these cases, we have
                    recently released our Custom Technology module. This module
                    allows users to put together their own model that can be
                    used in the main CRANE application. Please note that putting
                    together a model from scratch can be challenging and time
                    consuming, and we understand that there is a bit of a
                    learning curve. The following section includes a tutorial
                    video that can help you get started and guide you through
                    this process. If you do not have the resources to put
                    together a custom CRANE model yourself,{' '}
                    <a
                      href='https:/rhoimpact.com/contact'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Rho Impact
                    </a>{' '}
                    (collaborators on CRANE) offers consulting services,
                    including putting together a custom technology model for you
                    and guiding you through the analysis and results.
                  </p>
                </div>

                {/* Tutorial Videos */}
                <div className={page !== 5 ? 'hidden' : undefined}>
                  <div className='mb-8'>
                    <h2 className='h2 mb-4'>Tutorial Videos &amp; Demos</h2>
                    {/* <p className="text-gray-400">
                      Last updated -{" "}
                      <span className="text-green-500">June 30, 2020</span>
                    </p> */}
                  </div>
                  <h3>
                    I have my inputs ready. How do I generate a custom impact
                    analysis for my company or technology?
                  </h3>
                  <p className='text-gray-500'>
                    Below is a 15-minute tutorial video that we created to help
                    guide you through the process of inputting your customized
                    data and generating an analysis specific to your company or
                    technology and needs.
                  </p>
                  <HeroFullVideo videoURL='https://www.youtube.com/embed/8Ud_yl1aJTs' />
                  <p className='text-gray-500'>
                    If you cannot find your technology in CRANE or want to
                    further customize an existing CRANE model, you can use our
                    Custom Technology module. To guide you through the process
                    of creating a CRANE technology model, we have put together
                    the 30-minute tutorial video below.
                  </p>
                  <HeroFullVideo videoURL='https://www.youtube.com/embed/mXa8S7MHLEA' />
                  <p className='text-gray-500'>
                    We do not currently offer free demonstrations to individual
                    companies outside of consulting engagements. However, past
                    webinars can be viewed on our{' '}
                    <a
                      href='https://www.youtube.com/channel/UCQYTZZG6ijIPG32-ZVKvy8A'
                      target='_blank'
                      rel='noreferrer'
                    >
                      YouTube channel.
                    </a>
                  </p>
                </div>

                {/* FAQs */}
                <div className={page !== 6 ? 'hidden' : undefined}>
                  <div className='mb-8'>
                    <h2 className='h2 mb-4'>
                      Frequently Asked Questions &amp; Glossary of Term
                    </h2>
                    {/* <p className="text-gray-400">
                      Last updated -{" "}
                      <span className="text-green-500">June 30, 2020</span>
                    </p> */}
                  </div>
                  <h3>
                    What do I do if I have additional questions or don’t
                    understand terminology used in the tool?
                  </h3>
                  <p className='text-gray-500'>
                    A major challenge of the forward-looking carbon accounting
                    is the lack of common terminology. Not all of the
                    terminology used in CRANE may be familiar to you. In order
                    to assist you in understanding the specific definitions of
                    all CRANE taxonomy terms and to see those terms in relation
                    to more familiar ones, we have put together{' '}
                    <a
                      href='https://app.cranetool.org/glossary'
                      target='_blank'
                      rel='noreferrer'
                    >
                      this glossary
                    </a>{' '}
                    (you will need a CRANE account to access it).
                  </p>
                  <p className='text-gray-500'>
                    If you still have questions about CRANE, please visit our{' '}
                    <a
                      href='https://app.cranetool.org/faq'
                      target='_blank'
                      rel='noreferrer'
                    >
                      FAQ page.
                    </a>{' '}
                    We have included all the most common questions that we have
                    received. If you cannot find your question, please feel free
                    to contact us at <strong>info@cranetool.org</strong> and we
                    will do our very best to get back to you in a timely manner.
                  </p>
                </div>
              </main>

              {/* Nav sidebar */}
              <aside className='md:w-64 mb-16 md:mb-0 md:mr-10 md:flex-shrink-0'>
                <nav>
                  <ul style={{ fontSize: '0.9rem', listStyle: 'none' }}>
                    <li className='py-1 border-b border-gray-800'>
                      <a
                        style={{ textDecoration: 'none' }}
                        className={`flex items-center px-3 pb-1 group text-gray-400 hover:text-green-500 transition duration-150 ease-in-out ${
                          page === 1 && 'text-green-500'
                        }`}
                        href='#0'
                        onClick={(e) => {
                          e.preventDefault();
                          setPage(1);
                        }}
                      >
                        <span>Getting Started</span>
                        <svg
                          className='w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-green-500 group-hover:translate-x-1 transition duration-150 ease-in-out transform'
                          viewBox='0 0 12 12'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path d='M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z' />
                        </svg>
                      </a>
                    </li>
                    <li className='py-2 border-b border-gray-800'>
                      <a
                        style={{ textDecoration: 'none' }}
                        className={` pb-1 flex items-center px-3 group text-gray-400 hover:text-green-500 transition duration-150 ease-in-out ${
                          page === 2 && 'text-green-500'
                        }`}
                        href='#0'
                        onClick={(e) => {
                          e.preventDefault();
                          setPage(2);
                        }}
                      >
                        <span>CRANE Inputs &amp; Key Outputs</span>
                        <svg
                          className='w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-green-500 group-hover:translate-x-1 transition duration-150 ease-in-out transform'
                          viewBox='0 0 12 12'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path d='M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z' />
                        </svg>
                      </a>
                    </li>
                    <li className='py-2 border-b border-gray-800'>
                      <a
                        style={{ textDecoration: 'none' }}
                        className={`pb-1 flex items-center px-3 group text-gray-400 hover:text-green-500 transition duration-150 ease-in-out ${
                          page === 3 && 'text-green-500'
                        }`}
                        href='#0'
                        onClick={(e) => {
                          e.preventDefault();
                          setPage(3);
                        }}
                      >
                        <span>Registration</span>
                        <svg
                          className='w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-green-500 group-hover:translate-x-1 transition duration-150 ease-in-out transform'
                          viewBox='0 0 12 12'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path d='M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z' />
                        </svg>
                      </a>
                    </li>
                    <li className='py-2 border-b border-gray-800'>
                      <a
                        style={{ textDecoration: 'none' }}
                        className={`pb-1 flex items-center px-3 group text-gray-400 hover:text-green-500 transition duration-150 ease-in-out ${
                          page === 4 && 'text-green-500'
                        }`}
                        href='#0'
                        onClick={(e) => {
                          e.preventDefault();
                          setPage(4);
                        }}
                      >
                        <span>Custom Technology Modules</span>
                        <svg
                          className='w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-green-500 group-hover:translate-x-1 transition duration-150 ease-in-out transform'
                          viewBox='0 0 12 12'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path d='M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z' />
                        </svg>
                      </a>
                    </li>
                    <li className='py-2 border-b border-gray-800'>
                      <a
                        style={{ textDecoration: 'none' }}
                        className={`pb-1 flex items-center px-3 group text-gray-400 hover:text-green-500 transition duration-150 ease-in-out ${
                          page === 5 && 'text-green-500'
                        }`}
                        href='#0'
                        onClick={(e) => {
                          e.preventDefault();
                          setPage(5);
                        }}
                      >
                        <span>Tutorial Videos &amp; Live Demos</span>
                        <svg
                          className='w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-green-500 group-hover:translate-x-1 transition duration-150 ease-in-out transform'
                          viewBox='0 0 12 12'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path d='M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z' />
                        </svg>
                      </a>
                    </li>
                    <li className='py-2 border-b border-gray-800'>
                      <a
                        style={{ textDecoration: 'none' }}
                        className={`pb-1 flex items-center px-3 group text-gray-400 hover:text-green-500 transition duration-150 ease-in-out ${
                          page === 6 && 'text-green-500'
                        }`}
                        href='#0'
                        onClick={(e) => {
                          e.preventDefault();
                          setPage(6);
                        }}
                      >
                        <span>
                          {' '}
                          Frequently Asked Questions &amp; Glossary of Terms
                        </span>
                        <svg
                          className='w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-green-500 group-hover:translate-x-1 transition duration-150 ease-in-out transform'
                          viewBox='0 0 12 12'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path d='M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z' />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </aside>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Help;
