import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

class DonateCTA extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'cta section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      className
    );

    const innerClasses = classNames(
      'cta-inner section-inner reveal-scale-down',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
      split && 'cta-split'
    );

    return (
      <section {...props} className={outerClasses}>
        <div className='container'>
          <div className={innerClasses}>
          <h2 className='center-content' style={{ color: '#FFF', marginBottom: '20px' }}>
        Join our philanthropic partners and support CRANE
      </h2>
            <div className='cta-slogan'>
              <div className='container-xs'>
                <p className='m-0' style={{ color: '#FFF' }}>
                  The CRANE tool is managed and funded by Prime Coalition, a
                  nonprofit 501(c)(3) and catalytic investor. If you would like
                  to make a donation to support our work, please do so here or
                  contact us at impact@primecoalition.org.
                </p>
                <br></br>
                <p className='m-0' style={{ color: '#FFF' }}>
                  We thank you for your commitment toward our shared climate
                  goals.
                </p>
              </div>
            </div>
            <div className='cta-action'>
              <Button
                tag='a'
                color='secondary'
                wideMobile
                href='https://secure.qgiv.com/for/pci'
                target='_blank'
                rel='noreferrer'
              >
                Donate
              </Button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

DonateCTA.propTypes = propTypes;
DonateCTA.defaultProps = defaultProps;

export default DonateCTA;
