import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Image from "../elements/Image";
import Modal from "../elements/Modal";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

class HeroFullVideo extends React.Component {
  state = {
    videoModalActive: false,
  };
  openModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  };

  render() {
    const { topDivider, bottomDivider, videoURL } = this.props;

    const innerClasses = classNames(
      "hero-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    return (
      <div className="container-sm">
        <div className={innerClasses}>
          {/* <div className="hero-content">
              <div className="container-xs">
                <h2 className="m-0 reveal-from-top">
                Tutorial Video
                </h2>
              </div>
            </div> */}
          <div
            className="hero-figure reveal-from-bottom"
            data-reveal-delay="150"
          >
            <a
              data-video={videoURL}
              href="#0"
              aria-controls="video-modal"
              onClick={this.openModal}
            >
              <Image
                className="has-shadow"
                src={require("./../../assets/images/video-placeholder.svg").default}
                alt="Video"
                width={712}
                height={400}
              />
            </a>
          </div>
          <Modal
            id="video-modal"
            show={this.state.videoModalActive}
            handleClose={this.closeModal}
            video={videoURL}
            videoTag="iframe"
          />
        </div>
      </div>
    );
  }
}

HeroFullVideo.propTypes = propTypes;
HeroFullVideo.defaultProps = defaultProps;

export default HeroFullVideo;
